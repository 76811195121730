import React from 'react'
// import domtoimage from 'dom-to-image-more'
// import { CopyToClipboard } from 'react-copy-to-clipboard'
import { 
  JerseyBase,
  JerseyCuffs, 
  JerseyHem, 
  JerseyFolds, 
  JerseySideShadows, 
  JerseyShadows,
  JerseySleeves,
  JerseyShoulders,
  JerseySide,
  JerseyCollar
} from './JerseyAssets';

import AppData from '../AppData/AppData';

import './Jersey.scss'
import './JerseyPatterns.scss'

// function handlePatternDownload(id) {
//   domtoimage
//     .toPng(
//       document.getElementById(id),
//       { 
//         width: 1160,
//         height: 1520,
//         style: {
//           transform: 'scale(4)',
//           transformOrigin: 'top left'
//         }
//       }
//     )
//     .then(function (dataUrl) {
//       var link = document.createElement('a');
//       link.download = id + '@2x.png';
//       link.href = dataUrl;
//       link.click();
//     })
// }

class Jersey extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  // state = { copied: false }
  static contextType = AppData

  handleChange(event){
    if(event.target.checked){
      this.context.fixDesign(
        this.props.index,
        this.props.color1,
        this.props.color2,
        this.props.unit,
        true,
        this.props.shoulders,
        this.props.collar,
        this.props.side
      )
    }
    else {
      this.context.fixDesign(
        this.props.index,
        undefined,
        undefined,
        undefined,
        false,
        undefined,
        undefined,
        undefined
      )
    }
  }

  render() {
    const style = {
      '--color1': this.props.color1,
      '--color2': this.props.color2,
      '--unit': this.props.unit + 'px'
    }
    const id = 'jersey-' + this.props.index
    return (
      <div className='Jersey' style={ style }>
        <input 
          type="checkbox" 
          checked={ this.props.fixed }
          onChange={(e) => this.handleChange(e)}
          className="Jersey__Selector"
        />
        <div className='Lock' />
        <div id={ id } style={{ pointerEvents: 'none' }}>
          <JerseyBase pattern={ this.props.design  } />
        </div>
        <JerseySleeves />
        { this.context.fxVisibility && <JerseyHem /> }
        { this.context.fxVisibility && <JerseyCuffs /> }
        <JerseyShoulders variant={ this.props.shoulders } />
        <JerseyCollar variant={ this.props.collar } />
        <JerseySide variant={ this.props.side } />
        { this.context.fxVisibility && <JerseyShadows /> }
        { this.context.fxVisibility && <JerseySideShadows /> }
        { this.context.fxVisibility && <JerseyFolds /> }
        {/* <div className='Jersey__Buttons'>
          <div className='Download' style={{ opacity : this.props.fixed ? 1 : 0 }} onClick={() => { handlePatternDownload( id ) }}>Download Pattern</div>
          <CopyToClipboard 
            text={ 'Color1: ' + this.props.color1 + '\nColor2: ' + this.props.color2 }
            onCopy={() => this.setState({ copied: true })}
          >
            <div className='Copy' style={{ opacity : this.props.fixed ? 1 : 0 }}>Copy Colors</div>
          </CopyToClipboard>
          {
            this.state.copied
            ? (
              <div className='CopyNotice'>
                Copied!
                <span>{ setTimeout(() => this.setState({ copied: false }), 1000) }</span>
              </div>
            )
            : null
          }
        </div> */}
      </div>
    )
  }
}

export default Jersey
