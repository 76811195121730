import React from 'react'
import Jersey from '../components/Jersey/Jersey'
import Controls from '../components/Controls/Controls'
import AppData from '../components/AppData/AppData'
import AboutModal from '../components/AboutModal/AboutModal'

class Home extends React.Component {
  static contextType = AppData
  render(){
    return (
      <div className="Page">
        <svg viewBox="0 0 435 570" className="svg-definitions">
          <filter id="lighten01">
            <feComponentTransfer>
              <feFuncR type="linear" slope="1.1"/>
              <feFuncG type="linear" slope="1.1"/>
              <feFuncB type="linear" slope="1.1"/>
            </feComponentTransfer>
          </filter>

          <filter id="lighten02">
            <feComponentTransfer>
              <feFuncR type="linear" slope="1.2"/>
              <feFuncG type="linear" slope="1.2"/>
              <feFuncB type="linear" slope="1.2"/>
            </feComponentTransfer>
          </filter>

          <filter id="lighten05">
            <feComponentTransfer>
              <feFuncR type="linear" slope="1.5"/>
              <feFuncG type="linear" slope="1.5"/>
              <feFuncB type="linear" slope="1.5"/>
            </feComponentTransfer>
          </filter>

          <filter id="darken08">
            <feComponentTransfer>
              <feFuncR type="linear" slope="0.2"/>
              <feFuncG type="linear" slope="0.2"/>
              <feFuncB type="linear" slope="0.2"/>
            </feComponentTransfer>
          </filter>

          <filter id="darken06">
            <feComponentTransfer>
              <feFuncR type="linear" slope="0.4"/>
              <feFuncG type="linear" slope="0.4"/>
              <feFuncB type="linear" slope="0.4"/>
            </feComponentTransfer>
          </filter>

          <filter id="darken05">
            <feComponentTransfer>
              <feFuncR type="linear" slope="0.5"/>
              <feFuncG type="linear" slope="0.5"/>
              <feFuncB type="linear" slope="0.5"/>
            </feComponentTransfer>
          </filter>

          <filter id="darken04">
            <feComponentTransfer>
              <feFuncR type="linear" slope="0.6"/>
              <feFuncG type="linear" slope="0.6"/>
              <feFuncB type="linear" slope="0.6"/>
            </feComponentTransfer>
          </filter>

          <filter id="darken03">
            <feComponentTransfer>
              <feFuncR type="linear" slope="0.7"/>
              <feFuncG type="linear" slope="0.7"/>
              <feFuncB type="linear" slope="0.7"/>
            </feComponentTransfer>
          </filter>

          <filter id="darken02">
            <feComponentTransfer>
              <feFuncR type="linear" slope="0.8"/>
              <feFuncG type="linear" slope="0.8"/>
              <feFuncB type="linear" slope="0.8"/>
            </feComponentTransfer>
          </filter>

          <filter id="darken01">
            <feComponentTransfer>
              <feFuncR type="linear" slope="0.9"/>
              <feFuncG type="linear" slope="0.9"/>
              <feFuncB type="linear" slope="0.9"/>
            </feComponentTransfer>
          </filter>

          <filter id="blur05">
            <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
          </filter>

          <filter id="blur03">
            <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
          </filter>

          <filter id="blur02">
            <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
          </filter>

          <defs>
            <clipPath id="tshirt">
              <path d="M145,364.3369 C158.954,364.3369 229.977,362.5229 233.186,354.5689 C230.535,341.3139 221.334,249.8799 221.334,219.5969 C221.334,189.3149 226.654,139.6979 228.89,131.6869 C232.243,142.3069 233.364,146.5909 234.209,153.7339 C244.259,154.4789 269.692,151.9949 280.685,145.1019 C279.38,139.3259 279.194,137.6489 279.194,137.6489 C279.194,137.6489 278.635,128.3339 276.027,122.3709 C273.418,116.4089 268.761,91.4429 268.388,88.2759 C268.015,85.1089 265.593,57.3479 244.913,48.9639 C224.232,40.5799 194.235,26.9789 189.95,24.7429 C185.665,22.5079 183.331,21.6639 183.331,21.6639 C183.331,21.6639 170.29,27.8799 145,27.8799 C119.71,27.8799 106.669,21.6639 106.669,21.6639 C106.669,21.6639 104.335,22.5079 100.05,24.7429 C95.765,26.9789 65.768,40.5799 45.087,48.9639 C24.407,57.3479 21.985,85.1089 21.612,88.2759 C21.239,91.4429 16.582,116.4089 13.973,122.3709 C11.365,128.3339 10.806,137.6489 10.806,137.6489 C10.806,137.6489 10.62,139.3259 9.315,145.1019 C20.308,151.9949 45.741,154.4789 55.791,153.7339 C56.636,146.5909 57.757,142.3069 61.11,131.6869 C63.346,139.6979 68.666,189.3149 68.666,219.5969 C68.666,249.8799 59.465,341.3139 56.814,354.5689 C60.023,362.5229 131.046,364.3369 145,364.3369"></path>
            </clipPath>
          </defs>
        </svg>

        <h1>Jersey Design Playground</h1>
        <p>
          Design and compare beautiful soccer jerseys patterns. All made with SVG and CSS.<br/>Created by <a href="https://www.twitter.com/untallucas" target="_blank" rel="noopener noreferrer">@untallucas</a>
        </p>
        <p className='highlight'>
          Would you be interested in a pro version? I'm thinking on adding extra patterns, fully-editable designs, different clothes templates, downloadable images, code exports and galleries creation. <a href="https://forms.gle/EDCZ82evoybTiVEw7" target="_blank" rel="noopener noreferrer">Leave your email here</a> and I'll keep you posted.
        </p>

        <div className="Jerseys__Wrapper">
          {
            this.context.patterns.map((pattern, index) =>
            <Jersey 
              // INVARIANT
              key={ index }
              index={ index }
              pattern={ pattern.name }
              design={ pattern.className }
              // CUSTOM VARIANTS
              color1={ pattern.color1 ? pattern.color1 : this.context.color1 }
              color2={ pattern.color2 ? pattern.color2 : this.context.color2 }
              unit={ pattern.unit ? pattern.unit : this.context.unit }
              fixed={ pattern.fixed !== undefined ? pattern.fixed : false }
              shoulders={ pattern.shoulders !== undefined ? pattern.shoulders : this.context.shoulders }
              collar={ pattern.collar !== undefined ? pattern.collar : this.context.collar }
              side={ pattern.side !== undefined ? pattern.side : this.context.side }
            />
            )
          }
        </div>
        <Controls />
        <AboutModal />
      </div>
    )
  }
}

export default Home
