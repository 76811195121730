import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

import Home from '../screens/Home'

import { AppDataProvider } from '../components/AppData/AppData'

class App extends React.Component {
  render() {
    return (
      <AppDataProvider>
        <Router>
          <Switch>
            <Route path='/' component={ Home } exact />
            <Redirect to='/' />
          </Switch>
        </Router>
      </AppDataProvider>
    );
  }
}

export default App;
