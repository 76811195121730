import React from 'react'

import AppData from '../AppData/AppData'

import './AboutModal.scss'

class AboutModal extends React.Component {
  render() {
    return (
      <AppData.Consumer>
        {(context) => (
          !context.modalVisibility 
          ? (
            <div className="Modal__Trigger" onClick={() => { context.showModal() }}/>
          ) 
          : (
            <div className="Modal__Wrapper" onClick={() => { context.hideModal() }}>
              <div className="Modal__Box">
                <div className='Modal__Close' onClick={() => { context.hideModal() }}>&times;</div>
                <div className="Modal__Content">
                  <p>
                    Hi, my name is Lucas. I'm a UX designer and developer from Córdoba, Argentina, and this is a pet project I've started during quarantine.
                  </p>
                  <p>
                    You can check my work in <a href="https://www.behance.net/untallucas" rel="noopener noreferrer" target="_blank">Behance</a>, <a href="https://dribbble.com/untallucas" rel="noopener noreferrer" target="_blank">Dribbble</a> and <a href="https://github.com/untallucas" rel="noopener noreferrer" target="_blank">Github</a>.
                  </p>
                  <p>
                    If you are interested in a pro version, leave your email <a href="https://forms.gle/EDCZ82evoybTiVEw7" rel="noopener noreferrer" target="_blank">in this form</a> and I'll let you know as soon as I have any news.
                  </p>
                  <p>
                    For any ideas, bug reporting or features request, you can get in touch <a href="https://forms.gle/D7ZL8T1jMyuhgcP57" rel="noopener noreferrer" target="_blank">here</a>.
                  </p>
                </div>
              </div>
            </div>
          )
        )}
      </AppData.Consumer>
    )
  }
}
AboutModal.contextType = AppData

export default AboutModal
