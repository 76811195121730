import React from 'react'
import { ChromePicker } from 'react-color'
import Slider from 'rc-slider';

import AppData from '../AppData/AppData'

import './Controls.scss'

class Controls extends React.Component {
  state = {
    displayColorPicker1: false,
    displayColorPicker2: false,
    displayControls: true,
  }

  handleClickColor1 = () => {
    this.setState({ displayColorPicker1: !this.state.displayColorPicker1 })
  }

  handleClickColor2 = () => {
    this.setState({ displayColorPicker2: !this.state.displayColorPicker2 })
  }

  handleClosePicker1 = () => {
    this.setState({ displayColorPicker1: false })
  }

  handleClosePicker2 = () => {
    this.setState({ displayColorPicker2: false })
  }

  handleChangeCompleteColor1 = (color, event) => {
    this.context.setColor1(color.hex)
  }

  handleChangeCompleteColor2 = (color, event) => {
    this.context.setColor2(color.hex)
  }
  
  handleSliderChange = (value) => {
    this.context.setUnit( value )
  }

  handleControls = () => {
    this.setState({ displayControls: !this.state.displayControls })
  }

  render () {
    const collars = ['Base','Ve','Polo','Open','Square','Triangle','Mao','Vintage']
    const shoulders = ['Reset','Full','Stripes','Cutted','Short']
    const sides = ['Reset','Line','Slim']

    return (
      <AppData.Consumer>
        {(context) => (
          <div className="Controls">
            <div className={ this.state.displayControls ? 'Controls__Colapse Controls__Colapse__Visible' : 'Controls__Colapse Controls__Colapse__Hidden' } onClick={ this.handleControls }>{ this.state.displayControls ? "⇣" : '⇡' }</div>
            <div className={ this.state.displayControls ? 'Controls__Wrapper Controls__Wrapper__Visible' : 'Controls__Wrapper Controls__Wrapper__Hidden' }>
              <div className="Controls__Block">
                <div className="Controls__Label">Colors</div>
                <div className="Controls__Color">
                  <div className='Controls__SwatchWrapper'>
                    <div className="Controls__Swatch" style={{ background: this.context.color1 }} onClick={ this.handleClickColor1 }/>
                  </div>
                  <div className='Controls__SwatchWrapper'>
                    <div className="Controls__Swatch" style={{ background: this.context.color2 }} onClick={ this.handleClickColor2 }/>
                  </div>
                  <button className="Controls__Button" onClick={()=>{ context.invertColors()} }>Invert</button>
                  <button className="Controls__Button" onClick={()=>{ context.randomColors()} }>Random</button>
                  <button className="Controls__Button" onClick={()=>{ context.uncheckAll()} }>Uncheck All</button>
                  <button className="Controls__Button" onClick={()=>{ context.resetAll()} }>Reset</button>
                  {
                    this.context.fxVisibility
                    ? (
                      <button className="Controls__Button" onClick={()=>{ context.hideFX()} } style={{ width: '75px' }}>Hide FX</button>
                    )
                    : (
                      <button className="Controls__Button" onClick={()=>{ context.showFX()} } style={{ width: '75px' }}>Show FX</button>
                    )
                  }
                </div>
              </div>

              <div className="Controls__Block">
                <div className="Controls__Label">Size</div>
                  <div className="Controls__Slider">
                    <Slider 
                      min={ 5 }
                      max={ 55 }
                      defaultValue={ 30 }
                      step={ 5 }
                      value={ this.context.unit }
                      onChange={ this.handleSliderChange }
                      railStyle={{ }}
                      handleStyle={{ }}
                      trackStyle={{ }}
                    />
                </div>
              </div>

              <div className="Controls__Block">
                <div className="Controls__Label">Collar</div>
                <div className="Controls__RadioGroup">
                  {
                    collars.map((value, index) => {
                      return (
                        <label className="Controls__Radio" key={index}>
                          <input value={value} type="radio" name="collar" checked={this.context.collar === index} onChange={()=>{ context.setCollar(index)} } />
                          <span className="Controls__Radio__Label">{ index }</span>
                        </label>
                      )
                    })
                  }
                </div>
              </div>

              <div className="Controls__Block">
                <div className="Controls__Label">Side</div>
                <div className="Controls__RadioGroup">
                  {
                    sides.map((value, index) => {
                      return (
                        <label className={index === 0 ? 'Controls__Radio Controls__Radio--Reset' : 'Controls__Radio'} key={index}>
                          <input value={value} type="radio" name="side" checked={this.context.side === index} onChange={()=>{ context.setSide(index)} } />
                          <span className="Controls__Radio__Label">{ index === 0 ? '×' : index }</span>
                        </label>
                      )
                    })
                  }
                </div>
              </div>

              <div className="Controls__Block">
                <div className="Controls__Label">Shoulders</div>
                <div className="Controls__RadioGroup">
                  {
                    shoulders.map((value, index) => {
                      return (
                        <label className={index === 0 ? 'Controls__Radio Controls__Radio--Reset' : 'Controls__Radio'} key={index}>
                          <input value={value} type="radio" name="shoulders" checked={this.context.shoulders === index} onChange={()=>{ context.setShoulders(index)} } />
                          <span className="Controls__Radio__Label">{ index === 0 ? '×' : index }</span>
                        </label>
                      )
                    })
                  }
                </div>
              </div>
            </div>

            { this.state.displayColorPicker1 
              ? <div className="Controls__Popover">
                  <div className="Controls__Cover" onClick={ this.handleClosePicker1 }/>
                  <ChromePicker disableAlpha={ true } color={ this.context.color1 } onChangeComplete={ this.handleChangeCompleteColor1 } />
                  <div className='Controls__PickerClose' onClick={ this.handleClosePicker1 }>&times;</div>
                </div>
              : null
            }

            { this.state.displayColorPicker2
              ? <div className="Controls__Popover">
                  <div className="Controls__Cover" onClick={ this.handleClosePicker2 }/>
                  <ChromePicker disableAlpha={ true } color={ this.context.color2 } onChangeComplete={ this.handleChangeCompleteColor2 } />
                  <div className='Controls__PickerClose' onClick={ this.handleClosePicker2 }>&times;</div>
                </div>
              : null
            }
          </div>
        )}
      </AppData.Consumer>
    )
  }
}
Controls.contextType = AppData

export default Controls
