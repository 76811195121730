import React from 'react'

const AppData = React.createContext({ })

class AppDataProvider extends React.Component {
  state = {
    modalVisibility: false,
    fxVisibility: true,
    color1: '#A3DCFD',
    color2: '#FFFFFF',
    unit: 30,
    collar: 0,
    shoulders: 0,
    side: 0,
    patterns: [
      {
        name: 'Plain',
        className: '__Plain'
      },
      {
        name: 'Vertical Stripes',
        className: '__VerticalStripes'
      },
      {
        name: 'Horizontal Stripes',
        className: '__HorizontalStripes'
      },
      {
        name: 'Oblique Stripes',
        className: '__ObliqueStripes'
      },
      {
        name: 'Vertical Band',
        className: '__VerticalBand'
      },
      {
        name: 'Horizontal Band',
        className: '__HorizontalBand'
      },
      {
        name: 'Oblique Band',
        className: '__ObliqueBand'
      },
      {
        name: 'Oblique Band Inverted',
        className: '__ObliqueBandInverted'
      },
      {
        name: 'Vertical Halves',
        className: '__VerticalHalves'
      },
      {
        name: 'Horizontal Halves',
        className: '__HorizontalHalves'
      },
      {
        name: 'Oblique Halves',
        className: '__ObliqueHalves'
      },
      {
        name: 'Vertical ZigZag Halves',
        className: '__VerticalZigZagHalves'
      },
      {
        name: 'Horizontal ZigZag Halves',
        className: '__HorizontalZigZagHalves'
      },
      {
        name: 'Checkboard',
        className: '__Checkboard'
      },
      {
        name: 'Tilted Checkboard',
        className: '__TiltedCheckboard'
      },
      {
        name: 'Chevron',
        className: '__Chevron'
      },
      {
        name: 'ZigZag',
        className: '__ZigZag'
      },
      {
        name: 'Polka Dots',
        className: '__PolkaDots'
      },
      {
        name: 'Ipanema',
        className: '__Ipanema'
      },
      {
        name: 'Stitches',
        className: '__Stitches'
      },
      {
        name: 'Lightning',
        className: '__Lightning'
      },
      {
        name: 'Barbed Wire',
        className: '__BarbedWire'
      },
      {
        name: 'Mammatus',
        className: '__Mammatus'
      },
      {
        name: 'Rain',
        className: '__Rain'
      },
      {
        name: 'Waves',
        className: '__Waves'
      },
      {
        name: 'Vertical Thin Lines',
        className: '__VerticalThinLines'
      },
      {
        name: 'Horizontal Thin Lines',
        className: '__HorizontalThinLines'
      },
      {
        name: 'Oblique Thin Lines',
        className: '__ObliqueThinLines'
      },
      // {
      //   name: 'Fade',
      //   className: '__Fade'
      // },
      {
        name: 'Radial',
        className: '__Radial'
      },
      {
        name: 'Mesh',
        className: '__Mesh'
      },
      {
        name: 'Plain Chest',
        className: '__PlainChest'
      },
      {
        name: 'Transition',
        className: '__Transition'
      },
      {
        name: 'Double Stripes',
        className: '__DoubleStripes'
      },
      {
        name: 'Pallets',
        className: '__Pallets'
      },
      {
        name: 'Double Line',
        className: '__DoubleLine'
      },
      {
        name: 'Cross',
        className: '__Cross'
      },
      {
        name: 'Collar',
        className: '__Collar'
      },
      {
        name: 'Crosses',
        className: '__Crosses'
      },
      // {
      //   name: 'Sequential Fade',
      //   className: '__SequentialFade'
      // },
      {
        name: 'Lifeline',
        className: '__Lifeline'
      },
      {
        name: 'Flames',
        className: '__Flames'
      },
      {
        name: 'Stars',
        className: '__Stars'
      },
      {
        name: 'Diamonds',
        className: '__Diamonds'
      },
      {
        name: 'Patchwork',
        className: '__Patchwork'
      },
      {
        name: 'Burst',
        className: '__Burst'
      },
      {
        name: 'Half Circles',
        className: '__HalfCircles'
      },
      {
        name: 'Big In Japan',
        className: '__BigInJapan'
      },
      // {
      //   name: 'Compound Fade',
      //   className: '__CompoundFade'
      // },
      {
        name: 'Slanted',
        className: '__Slanted'
      },
      {
        name: 'Peaks',
        className: '__Peaks'
      },
      {
        name: 'Triangles',
        className: '__Triangles'
      },
      {
        name: 'Saw',
        className: '__Saw'
      },
      {
        name: 'Equilibrium',
        className: '__Equilibrium'
      },
      // {
      //   name: 'Basket',
      //   className: '__Basket'
      // },
      {
        name: 'Roadblock',
        className: '__Roadblock'
      },
      {
        name: 'Meme',
        className: '__Meme'
      },
      // {
      //   name: 'Pines',
      //   className: '__Pines'
      // }
    ]
  }

  render() {
    return (
      <AppData.Provider 
        value={{ 
          modalVisibility: this.state.modalVisibility,
          fxVisibility: this.state.fxVisibility,
          color1: this.state.color1, 
          color2: this.state.color2, 
          unit: this.state.unit,
          collar: this.state.collar,
          shoulders: this.state.shoulders,
          side: this.state.side,
          patterns: this.state.patterns,
          setColor1: (value) => this.setState({ color1: value }),
          setColor2: (value) => this.setState({ color2: value }),
          setUnit: (value) => this.setState({ unit: value }),
          setCollar: (value) => this.setState({ collar: value }),
          setShoulders: (value) => this.setState({ shoulders: value }),
          setSide: (value) => this.setState({ side: value }),
          invertColors: () => this.setState({ color1: this.state.color2, color2: this.state.color1 }),
          randomColors: () => this.setState({ color1: '#'+(Math.random().toString(16)+'0000000').slice(2,8).toUpperCase(), color2: '#'+(Math.random().toString(16)+'0000000').slice(2,8).toUpperCase() }),
          fixDesign: (index, color1, color2, unit, fixed, shoulders, collar, side) => {
            const newState = [...this.state.patterns]
            newState[index]['color1'] = color1
            newState[index]['color2'] = color2
            newState[index]['unit'] = unit
            newState[index]['fixed'] = fixed
            newState[index]['shoulders'] = shoulders
            newState[index]['collar'] = collar
            newState[index]['side'] = side
            this.setState({patterns: newState})
          },
          resetAll: () => {
            const patternsState = [...this.state.patterns]
            for (const value of patternsState) {
              value.color1 = undefined
              value.color2 = undefined
              value.unit = undefined
              value.fixed = undefined
              value.shoulders = undefined
              value.collar = undefined
              value.side = undefined
            }
            this.setState({
              color1: '#A3DCFD',
              color2: '#FFFFFF',
              unit: 30,
              collar: 0,
              shoulders: 0,
              side: 0,
              patterns: patternsState
            })
          },
          uncheckAll: () => {
            const patternsState = [...this.state.patterns]
            for (const value of patternsState) {
              value.color1 = undefined
              value.color2 = undefined
              value.unit = undefined
              value.fixed = undefined
              value.shoulders = undefined
              value.collar = undefined
              value.side = undefined
            }
            this.setState({
              patterns: patternsState
            })
          },
          showModal: () => {
            this.setState({ modalVisibility: true })
          },
          hideModal: () => {
            this.setState({ modalVisibility: false })
          },
          showFX: () => {
            this.setState({ fxVisibility: true })
          },
          hideFX: () => {
            this.setState({ fxVisibility: false })
          },
        }}
      >
        { this.props.children }
      </AppData.Provider>
    )
  }
}

export default AppData
export { AppDataProvider }
